import { zodResolver } from "@hookform/resolvers/zod";
import { useGoogleLogin } from "@react-oauth/google";
import { Divider, Flex, Form, Input, message } from "antd";
import { useMemo, useState } from "react";
import AppleSignin, { AppleAuthResponse, appleAuthHelpers } from "react-apple-signin-auth";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormItem } from "react-hook-form-antd";
import { useParams } from "react-router-dom";
import { z } from 'zod';

import { AppleIcon, GoogleIcon } from "@assets/icons";
import {
  KAnchor,
  KButton,
  KErrorText,
  KFormGroup,
  KMdTextSecondary88,
  KSmText700Secondary65,
  KXlText700Secondary88,
} from "@components";
import { homePageUrl } from "@constants/url";
import { useCustomNavigate, useFetchSummaryService } from "@hooks";
import { useAuthStore } from "@store";
import { AUTH_TYPE, EAuthErrorMsg, EAuthPage, EStatusCode, IParams } from "@types";
import { setToken as setLocalStorage } from "@utils/storage";
import { authAppleConfig } from 'config';
import {
  authenticateWithEmailOrSms,
  loginWithApple,
  loginWithGoogle,
} from "services/auth";
import { BackNavFormHeader } from "./BackButton";

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Email is invalid' }),
});

type FormData = z.infer<typeof schema>;

const appleAuthOptions = {
  ...authAppleConfig,
  scope: 'email name',
  state: '',
  nonce: 'nonce',
  usePopup: true,
};

export const WelcomeUser = () => {
  const { navigate } = useCustomNavigate();
  const { tempShareId } = useParams<IParams>();
  const { loginData, setCurrentPage, setLoginData, isCSLFlow } = useAuthStore();
  const { getPrincipal } = useFetchSummaryService();

  const [isThirdPartyLoginPending, setThirdPartyLoginPending] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<FormData>({
    defaultValues: {
      email: loginData.email,
    },
    resolver: zodResolver(schema),
    mode: 'all',
    disabled: isThirdPartyLoginPending,
  });

  const onErrorSubmitWithEmailOrSms = (error: any) => {
    if (+error?.response?.status === EStatusCode.FORBIDDEN) {
      setErrorMsg(EAuthErrorMsg.EMAIL_DO_NOT_MATCH);
    } else {
      message.error(error?.response?.data?.message || 'Email not found!');
    }
  };

  const onSubmitWithSMS: SubmitHandler<FormData> = async (data) => {
    try {
      await authenticateWithEmailOrSms({
        email: data.email,
        type: AUTH_TYPE.SMS,
        tempShareId,
      });
      setLoginData(data.email, AUTH_TYPE.SMS);
      setCurrentPage(EAuthPage.VERIFY_OTP_LOGIN);
    } catch (error: any) {
      onErrorSubmitWithEmailOrSms(error);
    }
  };

  const onSubmitWithEmail: SubmitHandler<FormData> = async (data) => {
    try {
      await authenticateWithEmailOrSms({
        email: data.email,
        type: AUTH_TYPE.EMAIL,
        tempShareId,
      });
      setLoginData(data.email, AUTH_TYPE.SMS);
      setCurrentPage(EAuthPage.VERIFY_OTP_LOGIN);
    } catch (error: any) {
      onErrorSubmitWithEmailOrSms(error);
    }
  };

  const handleLoginWithApple = async () => {
    setThirdPartyLoginPending(true);
    appleAuthHelpers.signIn({
      authOptions: appleAuthOptions,
      onSuccess: async (response: AppleAuthResponse) => {
        try {
          await loginWithApple({
            token: response.authorization.id_token,
            tempShareId,
          });
          setErrorMsg('');
          message.success('Login with Apple successfully');
          if (isCSLFlow && tempShareId) {
            await getPrincipal(tempShareId);
            navigate(`/${tempShareId}`, { replace: true });
          } else {
            setLocalStorage('Pin', new Date().getTime());
            navigate('/user');
          }
        } catch {
          setErrorMsg(EAuthErrorMsg.GOGGLE_APPLE_LOGIN_FAILED);
          message.error('Login with Apple failed');
        }
        setThirdPartyLoginPending(false);
      },
      onError: (error: unknown) => {
        setThirdPartyLoginPending(false);
      },
    });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        await loginWithGoogle({
          token: response.access_token,
          tempShareId,
        });
        setErrorMsg('');
        message.success('Login with Google successfully');
        if (isCSLFlow && tempShareId) {
          await getPrincipal(tempShareId);
          navigate(`/${tempShareId}`, { replace: true });
        } else {
          setLocalStorage('Pin', new Date().getTime());
          navigate('/user');
        }
      } catch (error) {
        setErrorMsg(EAuthErrorMsg.GOGGLE_APPLE_LOGIN_FAILED);
        message.error('Login with Google failed');
      }
      setThirdPartyLoginPending(false);
    },
    onError: () => {
      setErrorMsg(EAuthErrorMsg.GOGGLE_APPLE_LOGIN_FAILED);
      setThirdPartyLoginPending(false);
    },
    onNonOAuthError: () => {
      setThirdPartyLoginPending(false);
    },
  });

  const handleLoginWithGoogle = () => {
    setThirdPartyLoginPending(true);
    loginGoogle();
  };

  const isLoginPending = useMemo(
    () => (isSubmitting || isThirdPartyLoginPending),
    [isSubmitting, isThirdPartyLoginPending]
  );

  return (
    <Flex vertical gap={16} justify="flex-start" flex={1}>
      <BackNavFormHeader screenKey={isCSLFlow ? EAuthPage.CSL_WELCOME : EAuthPage.AUTH_SCREEN} />
      <KXlText700Secondary88>Welcome back!</KXlText700Secondary88>
      <Form>
        <KFormGroup label='Email'>
          <FormItem control={control} name='email'>
            <Input placeholder='Enter your email' size='large' />
          </FormItem>
        </KFormGroup>
        <Flex gap={16} flex={1}>
          <KButton
            title='Send SMS'
            type='primary'
            size='large'
            style={{ flex: 1 }}
            htmlType='button'
            onClick={handleSubmit(onSubmitWithSMS)}
            disabled={isLoginPending}
          />
          <KButton
            title='Send Email'
            type='primary'
            size='large'
            style={{ flex: 1 }}
            htmlType='button'
            onClick={handleSubmit(onSubmitWithEmail)}
            disabled={isLoginPending}
          />
        </Flex>
      </Form>
      <Divider plain>
        <KSmText700Secondary65>OR</KSmText700Secondary65>
      </Divider>
      <AppleSignin
        authOptions={appleAuthOptions}
        uiType="dark"
        buttonExtraChildren='Continue with Apple'
        onSuccess={() => { }}
        onError={() => { }}
        render={() => (
          <KButton
            icon={<AppleIcon />}
            title='Continue with Apple'
            size='large'
            onClick={handleLoginWithApple}
            disabled={isLoginPending}
            style={{
              backgroundColor: '#000',
              color: '#fff',
            }}
          />
        )}
      />
      <KButton
        icon={<GoogleIcon />}
        title='Continue with Google'
        size='large'
        type='default'
        onClick={handleLoginWithGoogle}
        disabled={isLoginPending}
      />
      {errorMsg && (
        <KErrorText>{errorMsg}</KErrorText>
      )}
      <Divider />
      <Flex vertical>
        <KMdTextSecondary88>
          Don’t have an account yet?
        </KMdTextSecondary88>
        <KAnchor
          $isUnderlined
          target="_blank"
          size="md"
          color="PrimaryBase"
          href={homePageUrl}
        >
          Download the app to create an account.
        </KAnchor>
      </Flex>
    </Flex>
  );
};
