type EventName = "start" | "completed";

const routeEventName = (event: EventName) => `route-change-${event}`;

export interface IEventDetail {
  from?: string;
  to?: string;
}

export const dispatchRouteChangeEvent = (
  event: EventName,
  data?: IEventDetail
) => {
  return window.dispatchEvent(
    new CustomEvent(routeEventName(event), {
      bubbles: false,
      cancelable: true,
      detail: data,
    })
  );
};

export const registerRouteChangeListener = (
  event: EventName,
  eventHandler: (event: CustomEvent<IEventDetail>) => void
) => {
  window.addEventListener(
    routeEventName(event),
    eventHandler as (e: Event) => void
  );
};

export const removeRouteChangeListener = (
  event: EventName,
  eventHandler: (event: CustomEvent<IEventDetail>) => void
) => {
  window.removeEventListener(
    routeEventName(event),
    eventHandler as (e: Event) => void
  );
};
