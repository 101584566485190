import { ECardTypeName, EDateTimeFormat } from "@types";
import moment from "moment";
import * as momentTimezone from "moment-timezone";

export const formatDate = (
  date?: Date | string,
  format: keyof typeof EDateTimeFormat = "FULL_MONTH_YEAR"
) =>
  date && moment(date).isValid()
    ? `${moment(date).format(EDateTimeFormat[format])}`
    : "";

export const getFullDateStr = (
  startDate?: Date | string,
  endDate?: Date | string
) => {
  if (!startDate || !moment(startDate).isValid()) return "";
  if (!endDate || !moment(endDate).isValid()) return formatDate(startDate);
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

export const getCardDateStr = (
  startTime: Date | string = "",
  endTime: Date | string = "",
  formatStr?: EDateTimeFormat
) => {
  const formatString =
    formatStr ?? EDateTimeFormat.FULL_DAY_MONTH_YEAR_WITH_SHORT_MONTH;
  const startStr = moment(startTime).format(formatString);
  const endStr = moment(endTime).format(formatString);
  if (!startTime) return "";
  if (!endTime) return `${startStr} - Present`;
  return `${startStr} - ${endStr}`;
};

export const formatTimeRangeCard = (
  startTime: string | undefined,
  timeEnd: string | undefined,
  dateFormat = EDateTimeFormat.FULL_MONTH_YEAR
) => {
  const momentStartTime = moment(startTime);
  const momentTimeEnd = timeEnd ? moment(timeEnd) : null;
  if (!momentStartTime.isValid()) return "";

  const formattedstartTime = momentStartTime
    ? momentStartTime.format(dateFormat)
    : "";
  const formattedTimeEnd = momentTimeEnd
    ? momentTimeEnd.format(dateFormat)
    : "Present";

  return `${formattedstartTime} - ${formattedTimeEnd}`;
};

export const getTodoDateStr = (
  startTime: Date | string = "",
  isCompleted: boolean
) => {
  if (isToday(startTime))
    return `Today at ${moment(startTime).format(
      EDateTimeFormat.TIME_AM_PM_NO_SPACE
    )}`;
  if (isTomorrow(startTime))
    return `Tomorrow at ${moment(startTime).format(
      EDateTimeFormat.TIME_AM_PM_NO_SPACE
    )}`;

  const startStr = formatDate(
    startTime,
    "FULL_DAY_MONTH_YEAR_WITH_SHORT_MONTH_AND_TIME_AM_PM"
  );
  if (!startTime) return "";
  if (isCompleted) return `Completed at ${startStr}`;
  return `${startStr}`;
};

export const isToday = (date?: Date | string) => {
  if (!date || !moment(date).isValid()) return false;
  return moment(date).isSame(new Date(), "day");
};

export const isTomorrow = (date?: Date | string) => {
  if (!date || !moment(date).isValid()) return false;
  const nextDay = moment().add(1, "days").startOf("day").toDate();
  return moment(date).isSame(nextDay, "day");
};

export const isPastDate = (date?: string) => {
  if (!date || !moment(date).isValid()) return false;
  return moment(date).isBefore(moment(), "day");
};

export const isPast = (date?: Date | string) => {
  if (!date || !moment(date).isValid()) return false;
  const momentDate = moment(date);
  const now = moment();
  return momentDate.isBefore(now);
};

export const isFuture = (date?: Date | string) => {
  if (!date || !moment(date).isValid()) return false;
  const momentDate = moment(date);
  const now = moment();
  return momentDate.isAfter(now);
};

export function isPastButToday(date?: string | null): boolean {
  if (!date || !moment(date).isValid()) return false;
  const inputDateTime = moment(date);
  return (
    inputDateTime.isBefore(moment(), "day") &&
    inputDateTime.isSame(moment(), "day")
  );
}

export const isUpcoming = (date?: Date | string) => {
  if (!date || !moment(date).isValid()) return false;
  return moment(date).isAfter(moment().add(1, "day"), "day");
};

export const parseDateTime = (dateTime: Date) => {
  if (!dateTime || !moment(dateTime).isValid())
    return {
      date: "",
      time: "",
    };
  const date = moment(dateTime).format(EDateTimeFormat.DATE);
  const time = moment(dateTime).format(EDateTimeFormat.TIME_AM_PM);
  return {
    date,
    time,
  };
};

export const getStartTimeDefault = (cardType: ECardTypeName) => {
  switch (cardType) {
    case ECardTypeName.Appointment:
      return moment().startOf("hour").add(1, "hour").format();
    case ECardTypeName.ToDos:
      return null;
    default:
      return moment().format();
  }
};

export const generateRoundedHour = () => {
  return moment()
    .add(1, "hour")
    .startOf("hour")
    .add(1, "hour")
    .format("YYYY-MM-DD HH:mm:ss");
};

export const displayDateTimeUpcoming = (dateTime?: string): string | null => {
  const inputDateTime = moment(dateTime);
  const today = moment();

  if (!inputDateTime.isValid()) return null;
  switch (true) {
    case isPastButToday(dateTime):
      return "Today";
    case inputDateTime.isSame(today, "day"):
      return inputDateTime.format(EDateTimeFormat.TIME_AM_PM_NO_SPACE);
    case isTomorrow(dateTime):
      return inputDateTime.format("dddd");
    default:
      return inputDateTime.format(EDateTimeFormat.FULL_MONTH_DAY);
  }
};

export const getTimezone = () => {
  const localTimezone = momentTimezone.tz.guess();
  const timezone = momentTimezone.tz.zone(localTimezone)?.name;
  return timezone ? timezone : "";
};

export const getTimeAgo = (timestamp: string | null) => {
  const date = new Date(timestamp || "");
  if (isNaN(date.getTime())) {
    return "";
  }
  const now = new Date();
  const diff: number = Math.abs(now.getTime() - date.getTime());
  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) {
    return "just now";
  } else if (hours < 1) {
    return `${minutes} min ago`;
  } else if (hours < 24) {
    return `${hours} hr ago`;
  } else if (days < 2) {
    return "yesterday";
  } else {
    return `${days} days ago`;
  }
};
