import { apiInstance, authApiInstance } from '@apis';
import {
  AuthEmailOrSmsPayload,
  EThirdPartyType,
  IThirdPartyLogin,
  UserInfo,
  VerifyOtpCodePayload,
  VerifyOtpResponse,
} from './auth.type';
import { IBaseRes, RegisterProfileProps } from '@types';
import { createUUID, setIdCookie, setToken } from '@utils/storage';

export const authenticateWithEmailOrSms = async (
  params: AuthEmailOrSmsPayload
) => {
  const res = await authApiInstance.get(`/v1/auth/otp/${params.email}`, {
    params: {
      type: params.type,
      temporaryShareId: params.tempShareId,
    },
  });
  return res.data;
};

export const verifyEmailExist = async (email: string) => {
  const res = await authApiInstance.get(`/v1/user/check-exist-email`, {
    params: {email},
  });
  return res.data;
};

export const signUpProfile = async (
  profile: Omit<RegisterProfileProps, 'reasonInput'>
) => {
  const res = await apiInstance.post(`/v1/user`, profile);
  return res.data;
};

export const verifyOtpCode = async (query: VerifyOtpCodePayload) => {
  const res = await authApiInstance.post<VerifyOtpResponse>(
    `/v1/auth/otp?deviceToken=${query.deviceToken}&code=${
      query.code
    }&email=${encodeURIComponent(query.email)}`
  );
  return res.data;
};

export const getMe = async () => {
  const res = await apiInstance.get<IBaseRes<UserInfo>>('/v1/auth/me');
  return res?.data?.data;
};

export const createPin = async (pin: string) => {
  const res = await apiInstance.post(`/v1/user/pin?pin=${pin}`);
  return res.data;
};

export const verifyThirdPartyLogin = async (payload: IThirdPartyLogin) => {
  const res = await authApiInstance.post<IBaseRes<{accessToken: string}>>(
    `/v1/auth/webview/third-party`,
    undefined,
    {
      params: {
        ...payload,
        temporaryShareId: payload.tempShareId,
      }
    }
  );
  return res.data;
};

export const loginWithGoogle = async ({ token, tempShareId }: {
  token?: string;
  tempShareId?: string;
}) => {
  if (!token) return;
  
  const deviceToken = createUUID();
  const res = await verifyThirdPartyLogin({
    deviceToken,
    token,
    type: EThirdPartyType.GOOGLE,
    tempShareId,
  });
  setToken('WebApp', res.data?.accessToken);
  setIdCookie(deviceToken);
};

export const loginWithApple = async ({ token, tempShareId }: {
  token?: string;
  tempShareId?: string;
}) => {
  if (!token) return;
  const deviceToken = createUUID();
  const res = await verifyThirdPartyLogin({
    deviceToken,
    token,
    type: EThirdPartyType.APPLE,
    tempShareId,
  });
  setToken('WebApp', res.data?.accessToken);
  setIdCookie(deviceToken);
};

export const checkEmailOrPhoneNumberExisted = async (payload: string) => {
  const url = `/v1/auth/exist-principal/${payload}`;
  const { data } = await authApiInstance.post(url);
  return data;
};

export const logoutWebAppService = async (fcm: string) => {
  const url = `/v1/auth/logout?fcm=${fcm}`;
  const { data } = await apiInstance.post(url);
  return data;
};
