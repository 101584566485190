import { KButton, KLgText700Secondary88 } from "@components";
import { KContext } from "@context";
import { useProfile, useUser } from "@store";
import { getToken, removeToken } from "@utils/storage";
import { Flex, Modal } from "antd";
import { modalProps } from "config/antd";
import { Dispatch, FC, SetStateAction, useContext } from "react";
import { useIntercom } from "react-use-intercom";
import { logoutWebAppService } from "@services";
import { useCustomNavigate } from "@hooks";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleAfterLogout?: () => void;
  handleAfterSelectCancel?: () => void;
  isRemoveAllToken?: boolean;
}

export const ModalConfirmLogout: FC<IProps> = ({
  isOpen,
  setIsOpen,
  handleAfterLogout,
  handleAfterSelectCancel,
}) => {
  const { navigate } = useCustomNavigate();
  const { hardShutdown } = useIntercom();
  const { setUserInfo } = useUser();
  const { setProfile } = useProfile();
  const { arrayPath, setTopics, setAmountOfCards } = useContext(KContext);

  const handleLogout = async () => {
    setIsOpen(false);
    hardShutdown();
    setTopics([]);
    setAmountOfCards([]);
    setProfile(null);
    removeToken('TempShare');
    removeToken('Pin');
    if (arrayPath?.[1] === 'user') {
      setUserInfo(null);
      await logoutWebAppService(getToken('FCM'));
      navigate('auth/sign-in');
    } else {
      handleAfterLogout?.();
    }
    if (getToken('FCM')) {
      removeToken('FCM');
    }
    removeToken('WebApp');
  };

  const onClickNo = () => {
    setIsOpen(false);
    handleAfterSelectCancel?.();
  };

  return (
    <Modal open={isOpen} {...modalProps} width={345}>
      <Flex gap={16} vertical align="center">
        <KLgText700Secondary88>
          Are you sure you want to log out?
        </KLgText700Secondary88>
        <Flex gap={16}>
          <KButton
            size="large"
            type="default"
            title="No, go back"
            onClick={onClickNo}
          />
          <KButton
            size="large"
            title="Yes, log out"
            onClick={handleLogout}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
