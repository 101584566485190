import { useKContext } from "@context";
import {
  getProfileBasicTemporary,
  getProfileByIdService,
  getProfileEmergencyAccessService,
} from "@services";
import { useProfile } from "@store";
import { IParams } from "@types";
import { getToken } from "@utils/storage";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

export const useFetchProfileService = () => {
  const { showProfileBasic } = useKContext();

  const { setLoadingProfile, setProfile } = useProfile();
  const { tempShareId, profileId, emergencyAccessId } = useParams<IParams>();

  const fetchProfileUser = useCallback(
    async (profileId?: string) => {
      const token = getToken("WebApp");
      if (!token || !profileId) return;
      try {
        setLoadingProfile(true);
        const res = await getProfileByIdService(profileId);
        setProfile(res);
      } catch (error) {
      } finally {
        setLoadingProfile(false);
      }
    },
    [setProfile, setLoadingProfile]
  );

  const fetchProfileTemporaryShare = useCallback(
    async (temporaryShareId?: string) => {
      if (!temporaryShareId || !showProfileBasic) return;
      (async () => {
        try {
          setLoadingProfile(true);
          const res = await getProfileBasicTemporary(temporaryShareId);
          setProfile(res);
        } catch (error: any) {
        } finally {
          setLoadingProfile(false);
        }
      })();
    },
    [setProfile, setLoadingProfile, showProfileBasic]
  );

  const fetchProfileEmerAccess = useCallback(
    async (emergencyAccessId?: string) => {
      if (!emergencyAccessId || !showProfileBasic) return;
      (async () => {
        try {
          setLoadingProfile(true);
          const res = await getProfileEmergencyAccessService(emergencyAccessId);
          setProfile(res);
        } catch (error: any) {
        } finally {
          setLoadingProfile(false);
        }
      })();
    },
    [setProfile, setLoadingProfile, showProfileBasic]
  );

  useEffect(() => {
    fetchProfileEmerAccess(emergencyAccessId);
    fetchProfileTemporaryShare(tempShareId);
    fetchProfileUser(profileId);
  }, [
    tempShareId,
    emergencyAccessId,
    profileId,
    fetchProfileEmerAccess,
    fetchProfileTemporaryShare,
    fetchProfileUser,
  ]);
};
