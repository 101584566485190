import { KFormUser } from "@components";
import { AllergiesOptions, AllergiesSeverityOptions } from "@constants/options";
import { ECardType } from "@types";
import { Flex } from "antd";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const AllergyDetail = () => {
  return (
    <>
      <KFormUser.Select
        name="type"
        label="Type"
        options={AllergiesOptions}
      />
      <KFormUser.Select
        label="Allergy Severity"
        name="allergySeverity"
        options={AllergiesSeverityOptions}
      />
      <Flex gap={16} justify="space-between">
        <KFormUser.DatePicker
          label="Start Date"
          name="startTime"
        />
        <KFormUser.EndDatePicker
          label="End Date"
          name="endTime"
        />
      </Flex>
      <KFormUser.Switch
        name="isNoLongerExperiencing"
        label="No longer experiencing?"
      />
      <KFormUser.OptionsSelector
        label="Primary Practitioner"
        cardType={ECardType.Practitioners}
      />
      <KFormUser.TextArea
        label="Notes"
        name="description"
      />
      <SetReminderSection />
      <AttachmentsDetail />
    </>
  );
};
