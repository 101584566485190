import { KContext } from '@context';
import { useCustomNavigate } from '@hooks';
import { EStatusCode, EUrlPath, IParams } from '@types';
import { getToken, removeToken } from '@utils/storage';
import axios from 'axios';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

authApiInstance.interceptors.request.use(
  (config) => {
    const tokenWebApp = getToken('WebApp');
    if (tokenWebApp) {
      config.headers['Authorization'] = `Bearer ${tokenWebApp}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const AxiosInterceptor = ({ children }: { children: React.ReactNode }) => {
  const { arrayPath, setShowErrorResult } = useContext(KContext);
  const { navigate } = useCustomNavigate();
  const { tempShareId, emergencyAccessId } = useParams<IParams>();
  useMemo(() => {
    const requestInterceptor = apiInstance.interceptors.request.use(
      (config) => {
        const temporaryToken = getToken('TempShare');
        const tokenWebApp = getToken('WebApp');
        const exportToken = getToken('Export');
        const emerAccessToken = getToken('EmergencyAccess');
        if (temporaryToken) {
          config.headers['x-temporary-share-token'] = temporaryToken;
        }
        if (tokenWebApp) {
          config.headers['Authorization'] = `Bearer ${tokenWebApp}`;
        }
        if (exportToken) {
          config.headers['x-file-export-token'] = exportToken;
        }
        if (emerAccessToken && arrayPath?.[1] === EUrlPath.EMERGENCY_ACCESS) {
          config.headers['x-temporary-share-token'] = emerAccessToken;
        }
        if (!tokenWebApp && arrayPath?.[1] === 'user') {
          navigate('/auth/sign-in');
          throw new axios.Cancel('No token available for user requests');
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = apiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const status = +error?.response?.data?.code;
        switch (status) {
          case EStatusCode.NOT_FOUND:
            setShowErrorResult(EStatusCode.NOT_FOUND);
            break;
          case EStatusCode.UNAUTHORIZED:
            if (arrayPath?.[1] === 'user') {
              removeToken('WebApp');
              removeToken('FCM');
              navigate('/auth/sign-in');
            }
            if (arrayPath?.[1] === EUrlPath.EMERGENCY_ACCESS) {
              removeToken('EmergencyAccess');
              navigate(`/${EUrlPath.EMERGENCY_ACCESS}/${emergencyAccessId}/welcome`);
            }
            if (tempShareId) {
              removeToken('TempShare');
              navigate(tempShareId);
            }
            break;
          case EStatusCode.BAD_REQUEST:
            if (arrayPath?.[1] === EUrlPath.EMERGENCY_ACCESS) {
              setShowErrorResult(EStatusCode.NOT_FOUND);
            }
            break;
          default:
            break;
        }
        return Promise.reject(error);
      }
    );

    return () => {
      apiInstance.interceptors.request.eject(requestInterceptor);
      apiInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [arrayPath, tempShareId, emergencyAccessId]);

  return <>{children}</>;
};

export * from './thirdParty'