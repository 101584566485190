import { AUTH_TYPE, IBaseRes } from "@types";

export type AuthEmailOrSmsPayload = {
  email: string;
  type: AUTH_TYPE;
  tempShareId?: string;
};

export type VerifyOtpCodePayload = {
  deviceToken: string;
  email: string;
  code: string;
};

export type VerifyOtpResponse = IBaseRes<{
  accessToken: string;
}>;

export type VerifyEmergencyAccessRes = VerifyOtpResponse

export type UserInfo = {
  _id: string;
  lastUpdateDataTime: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: string;
  role: string;
  isSetupPin: boolean;
  accessProfiles: string[];
  avatar?: string;
  reason: string[];
  subscription?: {
    expiresDate: string;
    transactionId: string;
  };
  isReceiveNotifications: boolean;
};

export enum EThirdPartyType {
  GOOGLE = "google",
  APPLE = "apple"
}

export interface IThirdPartyLogin {
  deviceToken: string;
  token: string;
  type: EThirdPartyType;
  tempShareId?: string;
}

