import { useContext, useEffect } from "react";

import { KContext } from "@context";
import { getToken, setToken } from "@utils/storage";

import { KinKeeperCircleIcon } from "@assets/icons";
import { saveDeviceTokenFCM } from "@services";
import { useUser } from "@store";
import { notification } from "antd";
import { getMessagingToken, onMessageListener } from "fcm/requestPermission";

export const UserNotificationHandler = () => {
  const { arrayPath } = useContext(KContext);
  const { userInfo, fetchUserInfo } = useUser();
  const [api, contextHolder] = notification.useNotification();

  const handleMessageListener = () => {
    onMessageListener().then((data) => {
      handleMessageListener();
      api.info({
        message: `${data.notification.title}`,
        description: `${data.notification.body}`,
        icon: <KinKeeperCircleIcon width={20} height={20} />,
        placement: "bottomRight",
      });
    });
  };

  const handleFCM = async () => {
    if (getToken("FCM")) return;
    try {
      const fcmToken = await getMessagingToken();
      fcmToken && (await saveDeviceTokenFCM(fcmToken));
      setToken("FCM", fcmToken);
      handleMessageListener();
      const channel = new BroadcastChannel("notifications");
      channel.addEventListener("message", (event) => {});
    } catch {}
  };

  useEffect(() => {
    if (arrayPath?.[1] !== "user" || userInfo) return;
    (async () => {
      try {
        await fetchUserInfo();
        handleFCM();
      } catch {}
    })();
  }, [arrayPath?.[1]]);

  return <>{contextHolder}</>;
};
