import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import App from "./App";
import GlobalStyle from "./components/GlobalStyle";
import { KContextProvider } from "./context";
import { ConfigProvider } from "antd";
import { theme } from "theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientId, intercomConfig } from "config";
import { KToast } from "components/Toast";
import { AxiosInterceptor } from "@apis";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider theme={theme}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <IntercomProvider {...intercomConfig}>
            <KContextProvider>
              <GlobalStyle />
              <KToast />
              <AxiosInterceptor>
                <App />
              </AxiosInterceptor>
            </KContextProvider>
          </IntercomProvider>
        </GoogleOAuthProvider>
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>
);
