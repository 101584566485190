import {
  ButtonAddCard,
  KFormUser,
  KLgText700
} from "@components";
import { QuestionItem } from "@pages/card-list/components/item";
import {
  ECardType,
  ICardAppointmentRes
} from "@types";
import { Divider, Flex } from "antd";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const AppointmentDetail = () => {
  const { getValues, watch } = useFormContext<ICardAppointmentRes>();

  const questions = useMemo(() => getValues("questions"), [watch("questions")]);
  return (
    <>
      <KFormUser.DateTimePicker label="Date" name="startTime"/>
      <KFormUser.OptionsSelector
        label="Primary Practitioner"
        cardType={ECardType.Practitioners}
      />
      <KFormUser.TextArea
        label="Location"
        name="location"
      />
      <KFormUser.TextArea
        label="Notes"
        name="description"
      />
      <SetReminderSection />
      <AttachmentsDetail />
      <Divider />
      <Flex vertical gap={16}>
        <Flex justify="space-between" align="center">
          <KLgText700>Active Questions</KLgText700>
          <ButtonAddCard cardType={ECardType.Questions}/>
        </Flex>
        {questions?.map((qs) => (
          <QuestionItem card={qs} key={qs._id} isDetail/>
        ))}
      </Flex>
    </>
  );
};
