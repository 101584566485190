import { Layout } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import {
  useLocation,
  useParams
} from "react-router-dom";
import styled from "styled-components";

import {
  KBreadcrumb,
  KCardMeatBall,
  KFooter,
  KHeader,
  KMdTextSecondary65,
  KModalAddCard,
  KProfileSwitch,
  ModalEnterPin,
} from "@components";
import { breakpointLarge, heightHeader } from "@constants/size";
import { useKContext } from "@context";
import {
  useFetchProfileService,
  useFetchSummaryService,
  useShowPin,
} from "@hooks";
import CreatePINPage from "@pages/auth/CreatePinPage";
import { ModalUpgradePremium } from "@pages/components";
import ErrorResult from "@pages/error-result";
import { createPinLogging } from "@services";
import {
  useAuthStore,
  useDetailCard,
  useProfile,
  useRoleUser,
  useUser,
} from "@store";
import { Color, EDateTimeFormat, ESpacing, EUrlPath, IParams } from "@types";
import { convertBootIntercomUser } from "@utils/third-party";
import { useIntercom } from "react-use-intercom";
import { UserNotificationHandler } from "./components";

const { Content } = Layout;

const MainContent = styled(Content)`
  padding: 0 50px;
  height: calc(100vh - ${heightHeader}px);
  background-color: ${Color.BgLayout};
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${breakpointLarge}px) {
    padding: 0;
  }
`;

const ContainerBreadcrumb = styled.div`
  padding: ${ESpacing.lg} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${breakpointLarge}px) {
    display: none;
  }
`;

const MainLayout = () => {
  const { tempShareId, profileId, emergencyAccessId } = useParams<IParams>();
  const { pathname } = useLocation();
  const { boot } = useIntercom();
  const {
    fetchSummaryEmerAccess,
    fetchSummaryTemporaryShare,
    fetchSummaryUser,
  } = useFetchSummaryService();
  const {
    expiredTime,
    showErrorResult,
    setProfileId,
    setTemporaryShareId,
    isValidToken,
  } = useKContext();
  const { setRoleUser, role } = useRoleUser();
  const { profile } = useProfile();
  const { userInfo } = useUser();
  const { isAuthenticated } = useAuthStore();
  const { showModal, handleClose } = useShowPin();
  const { openAddCard, setOpenAddCard } = useDetailCard();
  const [isLoggingPushed, setIsLoggingPushed] = useState(true);

  useEffect(() => {
    setTemporaryShareId(tempShareId ?? "");
    if (!isAuthenticated) return;
    fetchSummaryTemporaryShare(tempShareId);
  }, [isAuthenticated, tempShareId]);

  useEffect(() => {
    if (!emergencyAccessId) return;
    fetchSummaryEmerAccess(emergencyAccessId);
  }, [emergencyAccessId]);

  useFetchProfileService();

  useEffect(() => {
    setProfileId(profileId ?? "");
    (async () => {
      if (!profileId) return;
      fetchSummaryUser(profileId);
    })();
  }, [profileId]);

  useEffect(() => {
    setRoleUser(profile, userInfo?._id);
  }, [profile, userInfo]);

  useEffect(() => {
    if (!pathname.includes("user")) return;
    const bootIntercom = convertBootIntercomUser(userInfo);
    boot(bootIntercom);
  }, [userInfo]);

  useEffect(() => {
    if (!pathname.includes(EUrlPath.EMERGENCY_ACCESS) || !isValidToken) return;
    boot();
  }, [isValidToken]);

  useEffect(() => {
    if (isLoggingPushed || !userInfo || !profile || !role) return;
    (async () => {
      await createPinLogging(userInfo?._id, profile?._id, role);
      setIsLoggingPushed(true);
    })();
  }, [isLoggingPushed, profile, userInfo, role]);

  return (
    <Layout>
      {userInfo && !userInfo.isSetupPin && <CreatePINPage />}
      {!tempShareId && !emergencyAccessId && <KProfileSwitch />}
      <UserNotificationHandler />
      <KModalAddCard
        {...openAddCard}
        onOpen={(open) => setOpenAddCard({ open })}
      />
      <ModalUpgradePremium />
      <KHeader />
      {showErrorResult ? (
        <ErrorResult />
      ) : (
        <MainContent>
          {userInfo?.isSetupPin && showModal && pathname.includes("user") ? (
            <ModalEnterPin
              open={showModal}
              handleClose={handleClose}
              setIsLoggingPushed={setIsLoggingPushed}
            />
          ) : (
            <>
              <ContainerBreadcrumb>
                <KBreadcrumb />
                {tempShareId && (
                  <KMdTextSecondary65>
                    Access Expires:{" "}
                    {expiredTime
                      ? moment(expiredTime).format(
                          EDateTimeFormat.FULL_DAY_MONTH_YEAR_WITH_SHORT_MONTH_AND_TIME_AM_PM
                        )
                      : "Does not expire"}
                  </KMdTextSecondary65>
                )}
                {profileId && <KCardMeatBall type="profile" />}
              </ContainerBreadcrumb>
              <Outlet />
              <KFooter />
            </>
          )}
        </MainContent>
      )}
    </Layout>
  );
};

export default MainLayout;
