export enum OTPType {
  SMS = 'sms',
  Email = 'email',
}

export enum AuthTabName {
  Login = 'Log In',
  CreateAccount = 'Create Account',
}

export enum AvatarGroupSize {
  DEFAULT = 'default',
  LARGE = 'large',
  SMALL = 'small',
}

export const PIN_LENGTH = 4;
export const CODE_LENGTH = 6;
export const NOT_USE_TIME = 1 * 60000; // minutes
export const MAX_PIN_FAIL_COUNT = 5;

export enum ERole {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export enum EAuthErrorMsg {
  GOGGLE_APPLE_LOGIN_FAILED = 'There was a problem authenticating your account. Please try again or attempt a different method.',
  EMAIL_ALREADY_EXISTS = 'We already have a user registered with this email. Would you like to log in instead?',
  INPUT_PIN = 'To keep your information safe, a 4-digit PIN unlocks your account after a short period of inactivity.',
  EMAIL_DO_NOT_MATCH = 'This email address is not valid. Please use the email address that you received the share link.',
  NOT_RECOGNIZE_EMAIL = "Sorry, we don't recognize this email address. Please try again.",
  NOT_RECOGNIZE_PHONE = "Sorry, we don't recognize this phone number. Please try again.",
}

export enum EContactType {
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber'
}

export enum EReasonType {
  OwnHealth = 'Manage my own health',
  FamilyMember = 'Manage the health of a family member',
  PetHealth = `Manage a pet’s health`,
  SharedWithMe = 'Access something that was shared with me',
}
