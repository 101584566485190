import { Loading } from "@components";
import { KContext } from "@context";
import { useCustomNavigate } from "@hooks";
import AuthLayout from "@layouts/AuthLayout";
import MainLayout from "@layouts/MainLayout";
import { RedemptionPage } from "@pages/redemption";
import { EUrlPath } from "@types";
import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  authRoutes,
  emergencyAccessViewRoutes,
  mainAppRoutes,
  mainMobileRoutes,
  mobileEmergencyAccessViewRoutes,
  mobileTemporaryRoutes,
  temporaryRoutes,
} from "routes";
import "./App.css";
import { getToken } from "./utils";

const HomePage = lazy(() => import("@pages/home"));

const App: React.FC = () => {
  const { isMobile, arrayPath } = useContext(KContext);
  const { navigate } = useCustomNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (arrayPath?.[1] === EUrlPath.EMERGENCY_ACCESS) {
      const emrAccessToken = getToken("EmergencyAccess");
      if (emrAccessToken) return;
      return navigate(
        `/${EUrlPath.EMERGENCY_ACCESS}/${arrayPath?.[2]}/welcome`
      );
    }
    if (!getToken("WebApp") && arrayPath?.[1] === "user")
      return navigate("/auth/sign-in");
    if (pathname === "/") return navigate("/user");
  }, []);

  return (
    <>
      <Routes>
        <Route path={EUrlPath.EMPLOYEE_REDEMPTION}>
          <Route index element={<RedemptionPage />} />
        </Route>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <AuthLayout />
            </Suspense>
          }
        >
          {authRoutes}
          <Route path="auth/*" element={<Navigate to="/auth/sign-in" />} />
        </Route>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <MainLayout />
            </Suspense>
          }
        >
          {!isMobile ? (
            <>
              <Route path={EUrlPath.EMERGENCY_ACCESS}>
                <Route index element={<HomePage />} />
                <Route path=":emergencyAccessId" element={<HomePage />}>
                  {emergencyAccessViewRoutes}
                </Route>
              </Route>
              <Route path=":tempShareId" element={<HomePage />}>
                {temporaryRoutes}
              </Route>
              <Route path="/user">
                <Route index element={<HomePage />} />
                <Route path=":profileId" element={<HomePage />}>
                  {mainAppRoutes}
                </Route>
              </Route>
            </>
          ) : (
            <>
              <Route path=":tempShareId">
                <Route index element={<HomePage />} />
                {mobileTemporaryRoutes}
              </Route>
              <Route path="/user">
                <Route path=":profileId">
                  <Route index element={<HomePage />} />
                  {mainMobileRoutes}
                </Route>
              </Route>
              <Route path={EUrlPath.EMERGENCY_ACCESS}>
                <Route path=":emergencyAccessId">
                  <Route index element={<HomePage />} />
                  {mobileEmergencyAccessViewRoutes}
                </Route>
              </Route>
            </>
          )}
        </Route>
      </Routes>
    </>
  );
};

export default App;
