import { Flex } from "antd";

import { KFormUser } from "@components";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";
import { useDetailCard } from "@store";

export const NoteDetails = () => {
  const { editableDetail } = useDetailCard();
  return (
    <>
      <Flex gap={16} vertical flex={1}>
        <KFormUser.TextEditor
          label="Note"
          name="description"
          disabled={!editableDetail}
        />
        <Flex gap={16}>
          <KFormUser.DateTimePicker
            label="Date"
            name="startTime"
          />
        </Flex>
        <SetReminderSection />
        <AttachmentsDetail />
      </Flex>
    </>
  );
};
