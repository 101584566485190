import { KFormUser } from "@components";
import { Flex } from "antd";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const FilesDetail = () => {
  return (
    <>
      <AttachmentsDetail/>
      <Flex gap={16} vertical>
        <KFormUser.DateTimePicker name="startTime" label="Date"  />
        <KFormUser.TextArea
          name="description"
          label="Notes"
        />
        <SetReminderSection />
      </Flex>
    </>
  );
};
